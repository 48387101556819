<template>
  <div class="submit-cv">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url(/img/contactus/header-cv.jpg); background-size: cover"
    >
      <div class="viewport">
        <h1>Let's develop and improve together</h1>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="content">
        <h3>Send us your resume</h3>
        <p>Our team will get back to you as shortly as possible.</p>
        <form @submit.prevent enctype="multi">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              :class="nameEmpty ? 'is-invalid' : ''"
              id="floatingInput"
              v-model="name"
              placeholder="Your name"
              required
              @blur="validateName()"
            />
            <label for="floatingInput">Your Name</label>
            <div class="invalid-feedback">Please enter your name.</div>
          </div>
          <div class="form-floating mb-3">
            <input
              type="email"
              class="form-control"
              v-model="email"
              :class="emailEmpty ? 'is-invalid' : ''"
              @blur="validateEmail()"
              id="email"
              placeholder="Your Email"
              required
            />
            <label for="email">Your Email</label>
            <div class="invalid-feedback">Please enter your email address.</div>
          </div>

          <div class="form-floating mb-3">
            <select
              style="padding-left: 13px"
              class="form-select"
              id="empl_type"
              aria-label="Floating label select example"
              v-model="employment_type"
            >
              <option value="1">Employment</option>
              <option value="2">Self-employment</option>
            </select>
            <label for="empl_type">Desired employment type</label>
          </div>

          <div class="form-floating mb-3">
            <select
              style="padding-left: 13px"
              class="form-select"
              id="location"
              aria-label="Floating label select example"
              v-model="location"
            >
              <option value="1">Sweden</option>
              <option value="2">Bulgaria</option>
              <option value="3">Remotely</option>
            </select>
            <label for="location">Desired working location</label>
          </div>

          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              style="height: 280px"
              v-model="message"
            ></textarea>
            <label for="floatingTextarea">Message</label>
          </div>
          <input
            class="visually-hidden"
            @change="addFile()"
            type="file"
            id="file"
            ref="file"
            accept=".pdf,.docx,.doc,.dot,.dotm,.dotx'"
          />
          <div class="filepreview">
            <label for="file" class="select-file" :class="file ? 'active' : ''"
              ><i class="fa-solid fa-arrow-up-from-bracket"></i> Upload CV</label
            >
            <span class="filename" v-if="file">
              {{ file.name }}
              <span @click="removeFile()"><i class="fa-solid fa-xmark"></i></span>
            </span>
          </div>
          <div class="text-center">
            By submitting my CV, I agree to the
            <router-link to="/privacy-policy"> Privacy Policy.</router-link>
          </div>
          <div class="text-center">
            <button @click="submitCv()" class="btn btn-primary">Submit</button>
          </div>
          <div v-if="isLoading" class="loader d-flex align-items-center justify-content-center">
            <div>
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span>Submitting CV Please Wait...</span>
            </div>
          </div>
          <div v-if="success" class="alert alert-success" role="alert">
            Your email has been sent successfully
          </div>
          <div v-if="error" class="alert alert-danger" role="alert">
            There was an error sending your message! Please try again later.
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import titleMixin from '../mixins/titleMixin';
import axios from 'axios';
export default {
  mixins: [titleMixin],
  title: 'Submit CV | Comstream',
  data() {
    return {
      name: '',
      email: '',
      employment_type: '1',
      location: '1',
      message: '',
      file: null,
      isLoading: false,
      nameEmpty: false,
      emailEmpty: false,
      error: false,
      success: false,
    };
  },
  watch: {
    name: function (newVal) {
      if (newVal.length > 0) {
        this.name = newVal;
        this.nameEmpty = false;
      }
    },
    email: function (newVal) {
      if (newVal.length > 0) {
        this.email = newVal;
        this.emailEmpty = false;
      }
    },
  },
  methods: {
    addFile() {
      this.file = this.$refs.file.files[0];
    },
    removeFile() {
      this.file = null;
      this.$refs.file.value = null;
    },
    validateName() {
      if (this.name.length === 0) {
        this.nameEmpty = true;
      }
    },
    validateEmail() {
      if (this.email.length === 0) {
        this.emailEmpty = true;
      }
    },
    submitCv() {
      let location;
      let type = 'Employment';
      if (this.employment_type === '2') {
        type = 'Self - Employment';
      }
      if (this.location === '1') {
        location = 'Sweden';
      } else if (this.location === '2') {
        location = 'Bulgaria';
      } else {
        location = 'Remotely';
      }
      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('email', this.email);
      formData.append('type', type);
      formData.append('location', location);
      formData.append('content', this.message);
      formData.append('file', this.file);
      formData.append('fileEx', this.file.name.split('.').pop());
      if (this.name.length > 0 && this.email.length > 0 && this.file !== null) {
        this.isLoading = true;
        axios
          .post(
            'https://comstream.eu/api/submit-cv',
            formData,

            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )
          .then(() => {
            this.isLoading = false;
            this.success = true;
            this.name = '';
            this.email = '';
            this.company = '';
            this.emailText = '';
            this.file = null;
          })
          .catch(() => {
            this.error = true;
            this.isLoading = false;
          });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.submit-cv {
  .content {
    padding: 70px 0;
    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: 700;
      margin: 0px 0 12px 0;
    }
    p {
      text-align: center;
      margin-bottom: 20px;
    }
    form {
      position: relative;
      width: 720px;
      margin: 0 auto;
      .filepreview {
        margin: 20px 0;
        .select-file {
          font-size: 18px;
          display: inline-block;
          background: #656565;
          padding: 8px 20px 8px 15px;
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
          i {
            margin-right: 8px;
          }
          &.active {
            background: #a93224;
          }
        }
        .filename {
          margin-left: 20px;
          font-weight: 600;
          position: relative;
          span {
            cursor: pointer;
            position: absolute;
            top: -2px;
            right: -32px;
            background: rgba(0, 0, 0, 0.5);
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
        }
      }
      .text-center {
        margin: 25px 0;
      }
      .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        .spinner-border {
          width: 4rem;
          height: 4rem;
          color: #a93224 !important;
          margin: 12px auto;
          display: block;
        }
        span {
          display: block;
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .submit-cv {
    .content {
      h3 {
        font-size: 2rem;
      }
      form {
        width: 100%;
      }
    }
  }
}
</style>
